import React from 'react';

const CheckMark = () => (

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"  style={{ height: `17px` }}>
    <path fill="#62D533" d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
  </svg>

//   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" style={{ height: `25px` }}>

//  <path 
//      fill="#62D533"
//     d="M 11 3 C 6.5680032 3 3 6.5680032 3 11 C 3 15.431997 6.5680032 19 11 19 C 15.431997 19 19 15.431997 19 11 C 19 6.5680032 15.431997 3 11 3 z M 11 4 C 14.877998 4 18 7.1220024 18 11 C 18 14.877998 14.877998 18 11 18 C 7.1220024 18 4 14.877998 4 11 C 4 7.1220024 7.1220024 4 11 4 z M 14 8 L 9 13 L 7 11 L 6 12 L 9 15 L 15 9 L 14 8 z "
//     class="ColorScheme-Text"
//     />  
// </svg>

);

export default CheckMark;
