import React from "react";
import ReviewCard from "./ReviewCard";
import Slider from "react-slick/lib/slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const reviewData = [
  // {
  //   text: "Alex and Tamara did an amazing job deep cleaning our home after major renovations. They were professional, friendly, and efficient. I will definitely book and recommend future cleaning services from them!",
  //   name: "Jacqueline",
  // },
  // {
  //   text: "Just moved into my new apartment at the beginning of the month. Wilson and Farr came a couple days before my move in day and did a deep clean of my small apartment in just a few hours....a job that would have taken a few days on my own. They made this old apartment feel like a new house and made it feel like it was mine. Thank you so much for making my transition period better and way less stressful. I'm going to recommend their cleaning services to anyone who needs it.",
  //   name: "Ryan",
  // },
  // {
  //   text: "I was not disappointed! The job fulfilled my expectations and more. I would highly recommend to others and use again.",
  //   name: "Maria",
  // },
  // {
  //   text: "I hired Tamara and Alex to clean one of my listings as part of the move out service. They are a hard working, young, local couple with great energy and integrity. What I loved is that they were punctual, professional and clear in in their communication leading up to the appointment. I couldn't be more happy with the service provided and would highly recommend hiring them to any realtor, property manager or home owner in the Annapolis Valley. Keep up the good work!",
  //   name: "Melanie",
  // },
  {
    text: "Very thorough, very professional. When they came in, my new place hadn't had a top to bottom scrub since I'd moved in in the summer. Feels like a new home!",
    name: "Wes",
  },
  {
    text: "Wilson and Farr are the best at what they do. If it is your personal home or commercial cleaning you will not be disappointed. They go above and beyond any cleaning service. Their customer service and professionalism will leave you confident knowing your are getting the best quality cleaning! You will not be disappointed!",
    name: "Ashley",
  },
  {
    text: "Wilson and Farr are always professional and reliable. They clean my workspace from top to bottom in a timely manner. I so appreciate having reminders from them and when they reach out to me to get squeezed in when they're getting full. Such thoughtfulness. Their attention to detail is second to none and I'm always so happy with the end result. Highly recommend!",
    name: "Sarah",
  },
  {
    text: "Wilson and Farr saved us this past year with a new baby by helping keep our house in shape. Every time they came in to do a deep clean, I felt like I was on cloud nine. My house has never felt better ☺️",
    name: "Meaghan",
  },
  {
    text: "Wilson & Farr are top notch! Amazing attention to detail, great communicators and they always go the extra mile to make sure you get the best service possible. I have used their services many times and am always impressed. They are professional and courteous. I highly recommend them!",
    name: "Bruce"
  },
  {
    text: "We are so pleased with the service and professionalism. We had an addition added on to our house so dust and dirt permeated into everything. On top of that two golden retrievers and a backyard full of mud from construction. I came home to a sparkling house. There is nothing better than a spotless house",
    name: "Sacha"
  },
  {
    text: "Wilson and Farr are amazing! They are reliable, professional, and their attention to detail is impeccable. They were able to accommodate me on short notice and went above and beyond with their service. Thank you!!!",
    name: "Caitlin"
  },
  {
    text: "My house was in desperate need of some tlc. Cobwebs, dust mountains, built up dirt. These folks came and worked some magic. I have not wiped my foot on the side of my leg since I got home. Any parents or pet owners will know that’s a big deal. Things I never would have thought to look at are spotless. Our garbage can, which was an absolute state, looks like it was replaced with a new one. No detail was forgotten, I’m super impressed. Coming home sometimes has been a source of anxiety as the to-do list was becoming impossibly long, but when I got home today I felt relief. So I highly recommend these folks. Worth every penny.",
    name: "Jessica"
  },
  {
    text: "Wilson and Farr did an amazing job with cleaning our house!  From the very first contact they were prompt, professional, courteous and friendly.  Their cleaning of our house was on time and amazing!  We were preparing it for our tenants and found it hard to leave the house once we saw the transformative work they had accomplished.  From sparkling bathrooms and kitchen to like new cleaning of windows and screens on all floors.  We highly recommend Wilson and Farr!",
    name: "Barry"
  },
  {
    text: "When I reached out about having our windows cleaned, [they] were very helpful in providing the information about how Wilson & Farr work.  They came on time and did a stunning job - everything was both professional and of the highest quality.  I would recommend this company a thousand times over!",
    name: "Erna"
  },
  {
    text: "I had a great experience with this company! From the beginning, they got back to me very quickly, and explained the details over the phone of what would be involved. They did a deep clean and window treatment for a house I am about to sell. There were a few harder to clean items that they made shine, and they even went above and beyond cleaning out my attic. I also had to keep my cat in the house during the cleaning and they were very conscientious to ensure he didn't get outside, and made him feel comfortable. Would recommend highly!",
    name: "Emily"
  }
];


const ReviewCarousel = () => {

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    draggable: true,
    swipeToSlide: true,
    adaptiveHeight: false,
  };

  return (
    <div className="w-2/3 py-8" >
      <Slider {...settings} >
        {reviewData.map((review, i) => (
          <ReviewCard key={i} {...review} />
        ))}
      </Slider>
    </div>
  );
};

export default ReviewCarousel;
