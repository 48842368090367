import React from 'react';
import styled from "styled-components"
import CheckMark from "../../svg/CheckMark.js"

const Checklist = styled.ul`
  list-style: none;
  `
  
const ChecklistItem = styled.li`
  padding: 0.25rem;
  padding-left: 1rem;
  font-size: 1rem;
  `

const ListItem = ({item}) => {
  return (
    <div className='flex flex-row items-center'>
    <CheckMark/>
    <ChecklistItem>
      {item}
    </ChecklistItem>
    </div>
  )
}


const ServiceFeatures = () => {
  return (
    <section className="py-4 bg-white">
      <div className="container mx-auto px-8 items-center">
        <div className="md:flex md:flex-row">
          <div className="w-full md:w-1/2 md:px-12 py-6">
            <p className="text-gray-700 text-xl md:text-2xl text-left">
            Wilson & Farr Cleaning Solutions specializes in meticulous Deep Cleaning Services in the Annapolis Valley, 
            Nova Scotia. With flexible scheduling, eco-friendly cleaning options, and highly effective procedures, we 
            guarantee a comprehensive and immaculate cleaning experience for residential and commercial spaces.
            Our service area includes but is not limited to: Kentville, New Minas, Wolfville, Port Williams, Canning, Berwick, 
            Coldbrook and Windsor, Nova Scotia. Choose us for a thorough and pristine clean that will make your space sparkle.
            </p>
          </div>
          <div className="w-full md:w-1/2 md:flex flex-row font-semibold text-gray-800 p-8">
            <div className="w-full ">
              <Checklist>
                <ListItem item="Locally Owned & Operated" />
                <ListItem item="Insured & Vetted Staff" />
                <ListItem item="Professional & Detail-Oriented " />
                <ListItem item="A+ Rated Better Business Bureau Member " />
                <ListItem item="Eco-Friendly Cleaning Options " />
                <ListItem item="Carpets, Windows, Post-Construction & More  " />
              </Checklist>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceFeatures;
