import React from "react";
import bgImg from "../../images/green-leaf-wallpapers.webp";
import ReviewCarousel from "./ReviewCarousel";
import Star from "../../svg/Star";
import GoogleLogo from "../../images/google.png";

const Reviews = () => {
  return (
    <section
      id="features"
      className="md:pt-10 bg-white"
      style={{ position: "relative" }}
    >
      <div className="flex bg-black">
        <div
          className="w-screen bg-cover bg-center"
          style={{
            backgroundImage: `url(${bgImg})`,
            minHeight: `600px`,
            opacity: "0.8",
          }}
        ></div>

        <div
          className="flex flex-col w-full items-center justify-items-center"
          style={{ position: "absolute" }}
        >
          <a
            href="https://www.google.com/maps/place/Wilson+%26+Farr+Cleaning+Solutions/@44.9983711,-65.1584582,9z/data=!4m8!3m7!1s0x4b5857ebe54d8cdf:0xa502201a7477ad98!8m2!3d44.9997415!4d-64.5980353!9m1!1b1!16s%2Fg%2F11qnrz_33g?entry=ttu"
            target="_blank"
          >
            <div className="p-2 flex flex-col items-center justify-items-center pt-6">
              <img
                width="48"
                height="48"
                alt="Google Logo"
                src={GoogleLogo}
                className="img-shadow"
                style={{ backgroundColor: "#fff7" }}
              />
            </div>
            <div className="p-4 flex items-center justify-items-center align-middle">
              {/* 5 stars loop*/}
              {[1, 2, 3, 4, 5].map((i) => (
                <div key={i} className="items-center justify-items-center align-middle" >
                  <Star className="pt-12 px-2 m-6 text-yellow-400" />
                </div>
              ))}
            </div>
          </a>

          <ReviewCarousel />
        </div>
      </div>
    </section>
  );
};

export default Reviews;
