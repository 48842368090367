import React from 'react'


const ReviewCard = ({ name, text }) => {
  return (
    <div className='text-center'>
          <a href="https://www.google.com/maps/place/Wilson+%26+Farr+Cleaning+Solutions/@44.9992674,-64.9276735,10z/data=!4m8!3m7!1s0x4b5857ebe54d8cdf:0xa502201a7477ad98!8m2!3d44.9997415!4d-64.5980353!9m1!1b1!16s%2Fg%2F11qnrz_33g?hl=en-US&entry=ttu" target="_blank" >

        <p className='md:text-xl font-semibold'>
            {text}
        </p>
        </a>

        <p className='md:text-lg pt-1 italic'>- {name}</p>
    </div>
  )
}

export default ReviewCard